<template>
   <Layout>
      <PageHeader :title="$route.name == 'add-detail-one' ? 'Add Detail1' : 'Edit Detail1'" :items="items"></PageHeader>
      <div class="row" id="detailOne">
         <div class="col-12">
            <div class="card">
               <div class="card-body">
                  <b-form @submit.prevent="submitData" class="row">
                     <b-form-group class="col-12">
                        <label for="input-1">Title <span style="color: red">*</span></label>
                        <b-form-input id="input-1" v-model="form.name" placeholder="Enter Title" :class="{
                           'is-invalid': submitted && $v.form.name.$invalid
                           }"></b-form-input>
                        <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">
                           Title is required.
                        </div>
                     </b-form-group>
                     <b-form-group class="col-12">
                        <label for="input-2">Add Product LBL (in HTML format)</label>
                        <b-form-textarea id="input-2" v-model="form.description"
                          placeholder="Enter Product LBL" rows="5">
                        </b-form-textarea>
                     </b-form-group>
                     <b-form-group class="col-6">
                        <label for="input-3">Thumbnail <span style="color: red">*</span></label>
                        <b-form-file id="input-9" accept="image/*" placeholder="Choose a file or drop it here..."
                        @change="readFile($event, 'image')" ref="image" :class="{
                           'is-invalid': submitted && $v.image.$invalid }"></b-form-file>
                        <template v-if="$route.name == 'edit-detail-one' && edit.image_url">
                           <img :src="edit.image_url" width="128" height="128" style="object-fit: contain; margin-top: 5px" />
                        </template>
                        <template v-if="image_url">
                           <img :src="image_url" width="128" height="128" ref="image_url" style="object-fit: contain; margin-top: 5px" />
                        </template>
                        <div v-if="submitted && !$v.image.required" class="invalid-feedback">Thumbnail is required.
                        </div>
                     </b-form-group>
                     <b-form-group class="col-6">
                        <label for="input-4">Forum <span style="color: red;">*</span></label>
                        <b-form-select id="input-4" v-model="form.brand_forum_id" :options="getForumList"
                        value-field="id" text-field="title" :class="{
                           'is-invalid': submitted && $v.form.brand_forum_id.$invalid}">
                        </b-form-select>
                        <div v-if="submitted && !$v.form.brand_forum_id.required" class="invalid-feedback">
                        Forum is required.
                        </div>
                     </b-form-group>
                     <div class="col-6">
                        <b-button type="submit" variant="primary" class="mr-2"><span
                              v-if="this.$route.name == 'add-detail-one'">Save Data</span>
                           <span v-else>Update Data</span>
                        </b-button>
                     </div>
                  </b-form>
               </div>
            </div>
         </div>
      </div>
   </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MixinRequest from '../../../mixins/request';
import DetailOneMixin from '../../../mixins/ModuleJs/detail-one';
import { required } from "vuelidate/lib/validators";

export default {
   mixins: [MixinRequest, DetailOneMixin],
   components: {
      Layout,
      PageHeader
   },
   validations: {
      form: {
         name: { required },
         brand_forum_id: { required }
      },
      image: { required }
   },
   data() {
      return {
         items: [
         {
            text: "Home",
            href: "/",
         },
         {
            text: "List",
         },
         ],
      }
   }
}

</script>


